<template>
  <div>
    <el-tabs v-model="collectType"
             @tab-click="search">
      <el-tab-pane label="题目收藏"
                   name="1">
      </el-tab-pane>
      <el-tab-pane label="组卷收藏"
                   name="2"></el-tab-pane>
    </el-tabs>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="4"
              :xs="12">
        <el-select style="width:100%"
                   v-model="subject_id"
                   clearable
                   @change="search"
                   placeholder="选择科目">
          <el-option v-for="item in suubjectList"
                     :key="item.subject_id"
                     :label="item.subject_name"
                     :value="item.subject_id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="4"
              :xs="12"
              v-show="collectType==1">
        <el-select style="width:100%"
                   v-model="question_difficulty"
                   clearable
                   @change="search"
                   placeholder="选择难度">
          <el-option v-for="item,index in difficultList"
                     :key="index"
                     :label="item.text"
                     :value="item.val">
          </el-option>
        </el-select>
      </el-col>
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="4"
              :xs="12"
              v-show="collectType==1">
        <el-input clearable
                  style="width:100%"
                  v-model="favorite_tags"
                  @keyup.enter.native="search"
                  placeholder="标签"></el-input>
      </el-col>
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="12"
              :xs="12">
        <el-button type="primary"
                   @click="search">确定</el-button>
        <el-button @click="mydiscard"
                   v-show="collectType==1">我的丢弃</el-button>
      </el-col>
    </el-row>
    <!-- <Table ref="TablesRef"
           :tableData="tableData" /> -->
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="question_favorite_id"
                       align="center"
                       v-if="collectType==1"
                       key="question_favorite_id"
                       label="ID" />
      <el-table-column prop="question_id"
                       key="question_id"
                       align="center"
                       label="题目ID"
                       v-if="collectType==1" />
      <el-table-column prop="question_type_name"
                       key="question_type_name"
                       align="center"
                       label="题目类型"
                       v-if="collectType==1" />
      <el-table-column prop="question_category_name"
                       key="question_category_name"
                       align="center"
                       label="题目类别"
                       v-if="collectType==1" />
      <el-table-column prop="edu_paper_id"
                       key="edu_paper_id"
                       align="center"
                       label="试卷ID"
                       v-if="collectType==2" />
      <el-table-column prop="paper_name"
                       key="paper_name"
                       align="center"
                       label="试卷名称"
                       v-if="collectType==2" />
      <el-table-column prop="subject_name"
                       align="center"
                       label="科目" />

      <el-table-column prop="module_desc"
                       key="module_desc"
                       align="center"
                       label="组卷类型"
                       v-if="collectType==2" />

      <el-table-column prop="create_time"
                       key="create_time"
                       align="center"
                       label="组卷时间"
                       v-if="collectType==2" />

      <el-table-column prop="paper_score"
                       key="paper_score"
                       align="center"
                       label="总分"
                       v-if="collectType==2" />
      <el-table-column prop="collect_time"
                       key="collect_time"
                       align="center"
                       label="收藏时间"
                       v-if="collectType==2" />

      <el-table-column prop="favorite_tags"
                       key="favorite_tags"
                       align="center"
                       label="收藏标签"
                       v-if="collectType==1" />
      <el-table-column prop="question_difficulty_str"
                       key="question_difficulty_str"
                       align="center"
                       label="难度"
                       v-if="collectType==1">

        <template slot-scope="{row}"><span :class="'difficulty_tag difficulty_tag_'+row.question_difficulty">{{row.question_difficulty_str}}</span></template>
      </el-table-column>
      <el-table-column prop="time_input"
                       key="time_input"
                       align="center"
                       label="收藏时间"
                       v-if="collectType==1" />
      <el-table-column align="center"
                       fixed="right"
                       width="150"
                       label="操作">
        <template slot="header">
          <!-- <i class="el-icon-setting"
             slot="header"
             @click="showController"></i> -->
          操作
        </template>

        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="查看"
                        placement="top-start">
              <img src="@/assets/icons/icon-look@2x.png"
                   style="margin-right:8px"
                   @click="toInfo(row)"
                   alt="">
            </el-tooltip>
            <el-tooltip effect="dark"
                        content="修改"
                        placement="top-start">
              <img src="@/assets/icons/icon-edit@2x.png"
                   @click="showForm(row)"
                   style="margin-right:8px"
                   alt=""
                   v-if="collectType==1">
            </el-tooltip>

            <el-tooltip effect="dark"
                        content="删除"
                        placement="top-start">
              <img src="@/assets/icons/icon-delete@2x.png"
                   @click="delRow(row)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
export default {
  data () {
    return {
      collectType: '1',
      question_difficulty: '',
      page: 1,
      size: 10,
      totalElements: 0,
      suubjectList: [],
      difficultList: [],
      subject_id: '',
      favorite_tags: '',
      tableData: [],
      pageName: 'edu_myFavorite_admin',
      tableColumns: [
        { prop: 'question_favorite_id', align: 'center', label: 'ID', width: '', showToopic: false },
        { prop: 'question_id', align: 'center', label: '题目ID', width: '100', showToopic: true },
        { prop: 'time_input', align: 'center', label: '收藏时间', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目', width: '', showToopic: false },
        { prop: 'question_type_name', align: 'center', label: '题目类型', width: '', showToopic: false, },
        { prop: 'question_category_name', align: 'center', label: '题目类别', width: '', showToopic: false, isStatus: true },
        { prop: 'favorite_tags', align: 'center', label: '收藏标签', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'toInfo' },
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      menus: []
    }
  },
  components: {
    Form
  },
  created () {
    this.getSubject()
  },
  mounted () {
    // this.setTablesColums()
    let routes = this.$router.options.routes
    this.menus = routes.find(item => item.path == '/index').children
    this.getDiffcultyList()
  },
  methods: {
    getClass (prop, value) {
      return ''
    },
    getValue (prop, value, row) {
      if (prop == 'question_category_name') {
        var str = ''
        if (row.subject_id == 7 || row.subject_id == 11) {
          str = row.question_category_name
        }
        return str
      }
    },
    getDiffcultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get',
      }).then(res => {
        this.difficultList = res.data
      })
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      if (this.collectType == 1) {
        this.$http({
          url: '/api/v1/favorites/index',
          method: 'get',
          params: {
            page,
            limit,
            subject_id: this.subject_id,
            favorite_tags: this.favorite_tags,
            question_difficulty: this.question_difficulty
          }
        }).then(res => {
          this.tableData = res.data.list
          this.totalElements = res.data.count
        })
      } else if (this.collectType == 2) {
        this.$http({
          url: '/api/v1/combination/collect_list',
          method: 'get',
          params: {
            page,
            limit,
            subject_id: this.subject_id,
            favorite_tags: this.favorite_tags
          }
        }).then(res => {
          this.tableData = res.data.list
          this.totalElements = res.data.count
        })
      }

    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.suubjectList = res.data.subject
      })
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: this.collectType == 1 ? '/api/v1/favorites/favorite_del' : '/api/v1/combination/collect',
          method: 'post',
          data: {
            id: this.collectType == 1 ? row.question_favorite_id : undefined,
            edu_paper_id: this.collectType == 2 ? row.edu_paper_id : undefined
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    toInfo (row) {
      let len = this.menus.filter(item => item.path == '/testPapercomposition/templatePaper/addDetails').length //含有智能/模板、整卷组卷页面,，非组卷教师
      let len1 = this.menus.filter(item => item.path == '/testPapercomposition/testingCentre/info').length
      let len2 = this.menus.filter(item => item.path == '/testPapercomposition/chapter/info').length
      let len3 = this.menus.filter(item => item.path == '/testPapercomposition/autonomously/addDetails').length

      if (this.collectType == 1) {
        this.$router.push('/myFavorite/info?id=' + row.question_id)
      } else if (len > 0 || len1 > 0 || len2 > 0 || len3 > 0) {
        if (row.module == 6) { //模板组卷
          this.$router.push('/testPapercomposition/templatePaper/addDetails?edu_paper_id=' + row.edu_paper_id)
        }
        else if (row.module == 4) {//知识点组卷
          this.$router.push('/testPapercomposition/testingCentre/info?edu_paper_id=' + row.edu_paper_id)
        }
        else if (row.module == 5) {//章节组卷
          this.$router.push('/testPapercomposition/chapter/info?edu_paper_id=' + row.edu_paper_id)
        }
        else if (row.module == 3) {//整卷出卷
          this.$router.push('/testPapercomposition/autonomously/addDetails?edu_paper_id=' + row.edu_paper_id)
        }
      } else {
        this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + row.edu_paper_id + '&module=' + row.module)

      }
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    mydiscard () {
      this.$router.push('/mydiscard')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff;
}
::v-deep .el-tabs__item {
  height: 42px;
  padding: 0 15px;
  font-size: 16px;
  color: #999;
  &.is-active {
    color: #3f9eff;
  }
  &.is-top:nth-child(2) {
    padding-right: 25px;
  }
}
::v-deep .el-tabs__active-bar {
  border-radius: 2px;
  left: -10px;
  padding: 0 10px;
  height: 3px;
  background-color: #3f9eff;
}
::v-deep .el-col {
  margin-bottom: 10px;
}
.difficulty_tag {
  font-size: 15px;
  font-weight: 700;
}
// 容易
.difficulty_tag_1 {
  color: #5ac524;
}
// 较易
.difficulty_tag_2 {
  color: #b8d625;
}
// 中等
.difficulty_tag_3 {
  color: #ffde34;
}
// 较难
.difficulty_tag_4 {
  color: #ffa244;
}
// 困难
.difficulty_tag_5 {
  color: #fe4c4c;
}
</style>
